{
  "name": "@boosterfuels/core-monorepo",
  "version": "5.434.0",
  "private": true,
  "description": "The heart of all Booster products.",
  "repository": {
    "type": "git",
    "url": "https://github.com/boosterfuels/core-monorepo.git"
  },
  "files": [],
  "scripts": {
    "build": "nx run-many -t build-with-npm-overrides",
    "start": "nx run-many -t serve --output-style=stream --exclude=tenant-provisioning-job,vassal,boa",
    "start-api": "nx run-many -t serve --output-style=stream --projects=tag:\"type:api\"",
    "test": "nx run-many -t test",
    "test:local-coverage": "nx run-many -t test:coverage --output-style=static && nx run-many -t generate-coverage --output-style=static",
    "prepare": "is-ci || husky install",
    "e2e:ci:development": "npx nx run boa-e2e:e2e:ci-dev-server-development",
    "e2e:ci:staging": "npx nx run boa-e2e:e2e:ci-dev-server-staging",
    "e2e:local": "npx nx run boa-e2e:e2e:local --watch",
    "e2e:local:development": "npx nx run boa-e2e:e2e:local-development --watch",
    "e2e:local:staging": "npx nx run boa-e2e:e2e:local-staging --watch",
    "boa": "nx run-many -t serve -p boa rest-api --output-style=stream",
    "seed": "nx run groot:seed"
  },
  "dependencies": {
    "@ali-hm/angular-tree-component": "19.0.1",
    "@angular/animations": "19.1.3",
    "@angular/cdk": "19.1.1",
    "@angular/common": "19.1.3",
    "@angular/compiler": "19.1.3",
    "@angular/core": "19.1.3",
    "@angular/forms": "19.1.3",
    "@angular/localize": "19.1.3",
    "@angular/platform-browser": "19.1.3",
    "@angular/platform-browser-dynamic": "19.1.3",
    "@angular/router": "19.1.3",
    "@bluehalo/ngx-leaflet": "19.0.0",
    "@boosterfuels/booster-ui": "19.0.2",
    "@boosterfuels/diffler": "^2.0.4",
    "@boosterfuels/monogram": "^2.0.0",
    "@boosterfuels/pubsub": "^1.10.1-dev-7622b8",
    "@boosterfuels/routing-driver-assignment": "2.77.3-HEAD-475194ebdfd074fe2424f597074ccb9a2b36a52d",
    "@boosterfuels/routing-flex-schedule": "2.77.3-HEAD-475194ebdfd074fe2424f597074ccb9a2b36a52d",
    "@boosterfuels/routing-tools": "2.77.3-HEAD-475194ebdfd074fe2424f597074ccb9a2b36a52d",
    "@boosterfuels/subscriptions": "^0.6.56",
    "@boosterfuels/timekeeping": "^12.0.58",
    "@faker-js/faker": "^9.4.0",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@google-cloud/pubsub": "^3.2.1",
    "@google-cloud/secret-manager": "^5.6.0",
    "@hubspot/api-client": "^11.0.0",
    "@ng-bootstrap/ng-bootstrap": "18.0.0",
    "@ng-select/ng-select": "14.1.0",
    "@ngrx/component-store": "19.0.0",
    "@ngrx/effects": "19.0.0",
    "@ngrx/entity": "19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngrx/router-store": "19.0.0",
    "@ngrx/store": "19.0.0",
    "@ngrx/store-devtools": "19.0.0",
    "@nx/esbuild": "20.4.0",
    "@pdf-lib/fontkit": "0.0.4",
    "@popperjs/core": "^2.11.8",
    "@segment/analytics-node": "^1.3.0",
    "@sentry/node": "^7.95.0",
    "@turf/bearing": "^6.5.0",
    "@turf/center": "3.13.0",
    "@turf/centroid": "3.7.5",
    "@turf/circle": "^6.5.0",
    "@turf/convex": "4.6.0",
    "@turf/destination": "3.7.5",
    "@turf/distance": "4.4.0",
    "@turf/helpers": "4.4.0",
    "@turf/inside": "3.7.5",
    "@turf/line-intersect": "4.6.0",
    "@turf/point-on-surface": "3.7.5",
    "@turf/random": "^7.2.0",
    "@turf/turf": "5.1.6",
    "@turf/union": "4.5.2",
    "@types/dirty-chai": "^2.0.4",
    "adm-zip": "0.5.3",
    "agenda": "5.0.0",
    "angular-resizable-element": "7.0.2",
    "apexcharts": "^3.45.2",
    "archetype": "0.13.0",
    "assert": "^2.1.0",
    "axios": "^1.6.5",
    "axios16": "npm:axios@0.16.2",
    "axios18": "npm:axios@0.18.0",
    "bcryptjs": "2.4.3",
    "body-parser": "^1.20.2",
    "bootstrap": "5.3.3",
    "braintree": "^3.21.0",
    "bson": "^4.7.2",
    "bunyan-logger": "^1.0.0",
    "chalk": "1.1.3",
    "cloudinary": "^1.41.2",
    "co": "4.6.0",
    "cookie-parser": "^1.4.6",
    "core-js": "^3.35.1",
    "cors": "^2.8.5",
    "cropperjs": "^1.6.1",
    "crypto": "^1.0.1",
    "csv-parse": "4.16.3",
    "csv-stringify": "5.6.5",
    "date-fns": "^2.29.3",
    "date-fns-tz": "^2.0.0",
    "dd-trace": "^4.16.0",
    "debug": "2.6.9",
    "diacritics": "^1.3.0",
    "dotenv": "^16.4.1",
    "email-addresses": "3.0.3",
    "events": "^3.3.0",
    "exceljs": "3.5.0",
    "express": "4.21.2",
    "express-basic-auth": "^1.2.1",
    "fast-xml-parser": "^4.3.6",
    "fastest-levenshtein": "^1.0.12",
    "file-saver": "2.0.0-rc.3",
    "firebase-admin": "9.6.0",
    "form-data": "^3.0.0",
    "fs-extra": "9.0.1",
    "ftp": "0.3.10",
    "geojson": "0.5.0",
    "geojson-validation": "0.2.1",
    "github-markdown-css": "2.10.0",
    "google-auth-library": "^8.7.0",
    "google-maps": "4.3.3",
    "gzipper": "7.2.0",
    "handlebars": "^4.7.8",
    "http-proxy-middleware": "^2.0.6",
    "http-status": "1.7.3",
    "inline-css": "2.6.3",
    "is-ci": "^3.0.1",
    "jira-connector": "2.10.0",
    "js-md5": "0.4.2",
    "jsforce": "^1.11.1",
    "jsonexport": "^3.2.0",
    "jwt-decode": "^3.1.2",
    "leaflet": "^1.9.4",
    "leaflet-draw": "1.0.4",
    "leaflet-geosearch": "^4.0.0",
    "leaflet-google-places-autocomplete": "0.0.9",
    "leaflet.fullscreen": "3.0.1",
    "leaflet.markercluster": "^1.5.3",
    "libphonenumber-js": "^1.10.54",
    "lodash": "4.17.21",
    "lodash.clonedeep": "^4.5.0",
    "lodash.get": "4.4.2",
    "mandrill-api": "1.0.45",
    "marked": "^4.3.0",
    "mg-api-js": "^2.1.1",
    "minimist": "^1.2.6",
    "moment": "2.30.1",
    "moment-timezone": "0.5.44",
    "mongodb": "^4.17.1",
    "mongodb-extjson": "^2.1.5",
    "multer": "^1.4.4",
    "multer-storage-cloudinary": "4.0.0",
    "ng-apexcharts": "^1.8.0",
    "ng-inline-svg-2": "15.0.1",
    "ng-multiselect-dropdown": "1.0.0",
    "ngx-chips": "3.0.0",
    "ngx-cookie-service": "17.1.0",
    "ngx-image-zoom": "2.1.0",
    "ngx-markdown": "17.1.1",
    "ngx-mask": "17.0.4",
    "ngx-pagination": "6.0.3",
    "ngx-print": "^1.5.1",
    "node-cache": "4.2.0",
    "node-env-file": "^0.1.8",
    "node-fetch": "^2.7.0",
    "node-slack": "0.0.7",
    "node-slack-upload": "^2.0.0",
    "oauth-1.0a": "^2.2.6",
    "p-limit": "^3.1.0",
    "pdf-lib": "^1.17.1",
    "pdf-parse": "^1.1.1",
    "performant-array-to-tree": "^1.11.0",
    "pluralize": "6.0.0",
    "pubnub": "^7.4.5",
    "qs": "^6.11.2",
    "ramda": "0.28.0",
    "random-points-on-polygon": "^0.0.4",
    "randomstring": "^1.3.0",
    "request": "^2.88.2",
    "rxjs": "7.8.1",
    "semver": "5.5.0",
    "service-location-utils": "git+ssh://git@github.com/boosterfuels/service-location-utils.git#v0.0.7",
    "sherlockjs": "^1.4.2",
    "socks": "^2.7.1",
    "ssh2-sftp-client": "9.1.0",
    "standard-error": "^1.1.0",
    "tao-js": "0.1.1",
    "tslib": "^2.3.0",
    "turf-helpers": "3.0.12",
    "turf-point": "2.0.1",
    "twilio": "2.9.1",
    "urlsafe-base64": "1.0.0",
    "useragent": "^2.3.0",
    "winston": "2.4.5",
    "winston-transport": "^4.9.0",
    "winston3": "npm:winston@3.13.0",
    "xlsx": "0.18.5",
    "xml2js": "0.4.23",
    "zod": "^3.22.3",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.1.4",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "~19.1.0",
    "@angular/compiler-cli": "19.1.3",
    "@badeball/cypress-cucumber-preprocessor": "^20.0.5",
    "@bahmutov/cypress-esbuild-preprocessor": "^2.2.1",
    "@boosterfuels/booster-tools": "1.0.3",
    "@esbuild-plugins/node-modules-polyfill": "^0.1.4",
    "@eslint/eslintrc": "^2.1.1",
    "@eslint/js": "~8.57.0",
    "@ngrx/schematics": "19.0.0",
    "@nx/angular": "20.4.0",
    "@nx/cypress": "20.4.0",
    "@nx/eslint-plugin": "20.4.0",
    "@nx/express": "20.4.0",
    "@nx/jest": "20.4.0",
    "@nx/js": "20.4.0",
    "@nx/node": "20.4.0",
    "@nx/webpack": "20.4.0",
    "@nx/workspace": "20.4.0",
    "@rollup/plugin-commonjs": "^22.0.1",
    "@rollup/plugin-node-resolve": "^13.3.0",
    "@shelf/jest-mongodb": "4.1.7",
    "@swc-node/register": "~1.9.1",
    "@swc/core": "~1.5.7",
    "@swc/helpers": "~0.5.11",
    "@types/archetype": "npm:@boosterfuels/archetype-typings@^2.0.3",
    "@types/bcryptjs": "^2.4.6",
    "@types/bunyan": "^1.8.9",
    "@types/chai": "^4.3.6",
    "@types/chai-as-promised": "7.1.8",
    "@types/dirty-chai": "^2.0.4",
    "@types/express": "4.17.21",
    "@types/geojson": "^7946.0.11",
    "@types/jest": "29.5.14",
    "@types/leaflet": "^1.9.8",
    "@types/leaflet-draw": "^1.0.11",
    "@types/leaflet.markercluster": "^1.5.4",
    "@types/lodash": "4.17.0",
    "@types/mocha": "^10.0.2",
    "@types/multer": "^1.4.8",
    "@types/node": "22.10.3",
    "@types/raven": "^2.5.7",
    "@types/sinon-chai": "3.2.12",
    "@typescript-eslint/utils": "8.19.0",
    "c8": "7.11.0",
    "chai": "^4.3.10",
    "chai-as-promised": "7.1.1",
    "co-mocha": "1.2.2",
    "cypress": "^13.10.0",
    "dirty-chai": "^2.0.1",
    "esbuild": "^0.19.2",
    "eslint": "9.17.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-cypress": "4.1.0",
    "eslint-plugin-mocha": "^10.2.0",
    "faker": "5.0.0",
    "glob": "^10.3.10",
    "husky": "7.0.1",
    "jasmine-marbles": "~0.9.1",
    "jest": "29.7.0",
    "jest-environment-node": "^29.7.0",
    "jest-junit": "16.0.0",
    "lint-staged": "^11.2.3",
    "mocha": "^10.2.0",
    "mocha-junit-reporter": "^2.2.1",
    "mocha-multi-reporters": "1.5.1",
    "mock-express-response": "0.2.2",
    "mongodb-memory-server": "^9.0.1",
    "nock": "^13.5.0",
    "nx": "20.4.0",
    "prettier": "3.4.2",
    "prettier-plugin-gherkin": "^2.2.1",
    "rewire": "^7.0.0",
    "rollup": "^2.75.7",
    "rollup-plugin-dts": "^4.2.2",
    "sinon": "^16.0.0",
    "sinon-chai": "3.7.0",
    "ts-jest": "29.1.1",
    "ts-node": "10.9.1",
    "tsconfig-paths": "4.2.0",
    "typescript": "5.7.3",
    "typescript-eslint": "^8.13.0",
    "typescript-paths": "^1.5.1",
    "verdaccio": "^5.0.4",
    "webpack-node-externals": "3.0.0"
  },
  "optionalDependencies": {
    "@nx/nx-darwin-arm64": "20.3.0",
    "@nx/nx-darwin-x64": "20.3.0",
    "@nx/nx-linux-x64-gnu": "20.3.0",
    "@nx/nx-win32-x64-msvc": "20.3.0",
    "@rollup/rollup-darwin-x64": "4.13.2",
    "@rollup/rollup-linux-x64-gnu": "4.13.2"
  },
  "engines": {
    "node": ">= 22.0.0",
    "npm": ">= 8.0.0"
  },
  "lint-staged": {
    "**/*.{js,ts}": "prettier --write --ignore-unknown"
  },
  "config": {
    "mongodbMemoryServer": {
      "downloadDir": "./node_modules/mongodb-memory-server",
      "version": "6.0.4"
    }
  },
  "nx": {
    "includedScripts": []
  },
  "overrides": {
    "moment": "2.30.1",
    "moment-timezone": "0.5.44"
  }
}
